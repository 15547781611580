"use client";

import {
  ButtonContext,
  ButtonProps as RACButtonProps,
  Button as RACButton,
  useContextProps,
  composeRenderProps,
} from "react-aria-components";
import { compose, cva, type VariantProps } from "@atorie/core/cva";
import { type ElementRef, type ForwardedRef, forwardRef } from "react";

export const buttonVariants = compose(
  cva({
    base: [
      "avenir inline-flex h-12 w-52 items-center justify-center border-2 px-3 text-lg font-medium transition-colors focus:outline-none",
    ],
    defaultVariants: {
      variant: "primary",
    },
    variants: {
      isDisabled: {
        true: "cursor-not-allowed opacity-90 shadow-none invert-[0.28]",
      },
      variant: {
        destructive: [
          // base
          "border border-red-700/90 bg-red-600 text-white",
          // hover
          "hover:bg-red-700",
          // pressed
          "pressed:bg-red-800",
        ],
        primary: [
          // base
          "border-zinc-800 bg-white text-zinc-800",
          // hover
          "hover:opacity-85",
          // pressed
          "pressed:opacity-75",
        ],
        secondary: [
          // base
          "border-2 border-neutral-800 bg-neutral-200 text-neutral-800",
          // hover
          "hover:bg-zinc-300",
          // pressed
          "pressed:bg-zinc-400",
        ],
        ghost: [
          // base
          "size-fit border-none text-zinc-800 no-underline shadow-none",
          // hover
          "hover:text-gray-500 hover:underline",
          // pressed
          "pressed:text-black-700 pressed:underline",
        ],
        icon: [
          // base
          "h-10 w-10 rounded-full border-none p-2.5",
          // hover
          "hover:bg-zinc-100",
          // pressed
          "pressed:bg-zinc-200",
        ],
      },
    },
  })
);

export type ButtonProps = RACButtonProps &
  VariantProps<typeof buttonVariants> & {};

function BaseButton(
  props: ButtonProps,
  ref: ForwardedRef<ElementRef<"button">>
) {
  const [{ className, variant, ...buttonProps }, buttonRef] = useContextProps(
    props,
    // @ts-ignore
    ref,
    ButtonContext
  );

  return (
    <RACButton
      {...buttonProps}
      ref={buttonRef}
      className={composeRenderProps(className, (className, renderProps) =>
        buttonVariants({ ...renderProps, className, variant })
      )}
    />
  );
}

// @ts-ignore
const Button = forwardRef<HTMLButtonElement, ButtonProps>(BaseButton);

export default Button;
export { Button, ButtonContext };
