import {Dialog, DialogProps, Modal, ModalOverlay} from 'react-aria-components'

import {cx} from '@atorie/core/cva'

export type SheetProps = {
  isOpen: boolean
  onClose: () => void
  className?: string
  children?: DialogProps['children']
}

export function Sheet({isOpen, onClose, children, className}: SheetProps) {
  return (
    <ModalOverlay
      isOpen={isOpen}
      isDismissable
      onOpenChange={() => {
        onClose()
      }}
      className={({isExiting}) =>
        cx(
          'fixed inset-0 flex items-center justify-center bg-black/50',
          isExiting && 'animate-fade-out',
        )
      }
    >
      <Modal
        className={() =>
          cx(
            'm-lg:w-1/3 fixed inset-y-0 left-0 transition-transform max-sm:w-3/4 sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4',
          )
        }
      >
        <Dialog
          className={cx(
            'flex size-full flex-col space-y-6 overflow-y-scroll bg-white outline-none',
            className,
          )}
        >
          {children}
        </Dialog>
      </Modal>
    </ModalOverlay>
  )
}
