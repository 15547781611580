'use client'

import {forwardRef, type ForwardedRef} from 'react'
import {
  Heading as RACHeading,
  useContextProps,
  HeadingContext,
  type HeadingProps as RACHeadingProps,
} from 'react-aria-components'

import {cva, type VariantProps} from '@atorie/core/cva'


export const headingVariants = cva({
  base: ['font-medium text-neutral-800'],
  variants: {
    size: {
      sm: 'text-sm',
      lg: 'text-lg',
      xl: 'text-xl',
    },
    weight: {
      light: 'font-light',
      medium: 'font-medium',
      bold: 'font-bold',
    },
  },
  defaultVariants: {
    size: 'xl',
    weight: 'medium',
  },
})

export type HeadingProps = 
  RACHeadingProps &
    VariantProps<typeof headingVariants> & {}


function HeadingBase(props: HeadingProps, ref: ForwardedRef<HTMLHeadingElement>) {
  const [{children, className, size, weight, ...headingProps}, headingRef] =
    useContextProps(props, ref, HeadingContext)

  return (
    <RACHeading
      {...headingProps}
      className={headingVariants({className, size, weight })}
      ref={headingRef}
    >
      {children}
    </RACHeading>
  )
}

const Heading = forwardRef(HeadingBase)

export {Heading, HeadingContext}
