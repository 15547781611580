'use client'

import {
  composeRenderProps,
  Link as RACLink,
  type LinkProps as RACLinkProps,
} from 'react-aria-components'

import {compose, cva, type VariantProps} from '@atorie/core/cva'

const linkVariants = compose(
  cva({
    base: ['avenir items-center justify-center text-neutral-800 underline'],

    variants: {
      isDisabled: {
        true: 'cursor-default no-underline',
      },
      variant: {
        solid: [
          'flex',
          'rounded-lg px-3 py-[calc(theme(spacing[1.5])-1px)] text-sm/6 font-medium uppercase tracking-wide no-underline shadow-sm transition-colors',
          // base
          'border border-black bg-black text-white',
          // hover
          'hover:bg-gray-500',
          // pressed
          'pressed:bg-black-700',
        ],
        unstyled: ['no-underline', 'outline-none', 'border-none'],
        underline: [
          'text-sm/6 transition-colors',
          // hover
          'hover:text-gray-500',
          // pressed
          'pressed:text-black-700',
        ],
      },
    },
  }),
)

export type LinkProps = RACLinkProps &
    VariantProps<typeof linkVariants> & {}

export function Link({className, variant, ...props}: LinkProps) {
  return (
    <RACLink
      {...props}
      className={composeRenderProps(className, (className, renderProps) =>
        linkVariants({...renderProps, className, variant}),
      )}
    />
  )
}
