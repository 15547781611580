'use client'

import {forwardRef, type ForwardedRef} from 'react'
import {
  Text as RACText,
  useContextProps,
  type TextProps as RACTextProps,
  TextContext
} from 'react-aria-components'

import {cva, type VariantProps} from '@atorie/core/cva'

export const textVariants = cva({
  base: ['avenir text-lg text-neutral-800'],
  variants: {
    size: {
      base: 'text-sm',
    },
    weight: {
      light: 'font-light',
      medium: 'font-medium',
      normal: 'font-normal',
    },
    font: {
      anton: `avenir`,
    },
  },
  defaultVariants: {
    size: 'base',
    weight: 'normal',
  },
})

type TextProps = RACTextProps & VariantProps<typeof textVariants> & {}

function TextBase(props: TextProps, ref: ForwardedRef<HTMLElement>) {
  const [{children, className, size, weight, font, ...textProps}, textRef] =
    useContextProps(props, ref, TextContext)

  return (
    <RACText
      {...textProps}
      className={textVariants({className, size, weight, font})}
      ref={textRef}
    >
      {children}
    </RACText>
  )
}

const Text = forwardRef(TextBase)

export { Text, TextContext}
