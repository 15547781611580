import { signInWithRedirect } from "aws-amplify/auth";
import { FcGoogle } from "react-icons/fc";
import { useState } from "react";
import toast from "react-hot-toast";

import { cx } from "@atorie/core/cva";

import { Button } from "./button";

export interface SignInWithGoogleProps {
  className?: string;
}

export function SignInWithGoogleButton({ className }: SignInWithGoogleProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSignIn = async () => {
    try {
      setIsLoading(true);
      await signInWithRedirect({ provider: "Google" });
    } catch (error) {
      console.error("Google sign in error:", error);
      toast.error("Failed to sign in with Google");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={cx(
        "relative isolate flex items-center justify-center",
        className
      )}
    >
      <Button
        variant="primary"
        className={cx(
          "avenir relative flex h-14 w-full items-center justify-center gap-3"
        )}
        onPress={handleSignIn}
        isDisabled={isLoading}
      >
        <FcGoogle />
        <span className="text-lg">
          {isLoading ? "Connecting..." : "Continue with Google"}
        </span>
      </Button>
    </div>
  );
}

export default SignInWithGoogleButton;
